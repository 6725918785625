import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const staffsService = createApi({
  reducerPath: 'staffs',
  baseQuery: baseQuery,
  tagTypes: ['Staffs'],
  endpoints: (builder) => ({
    // 👉 Get All Staffs
    getAllStaffs: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/staffs',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Staffs'],
    }),

    // 👉 Get Staff Details
    getStaffDetails: builder.query({
      query: (id) => ({
        url: `/staffs/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Staffs', id: arg }],
    }),

    // 👉 Create Staff
    createStaff: builder.mutation({
      query: (payload) => {
        return {
          url: `/staffs`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Staffs'],
    }),

    // 👉 Update Staff Details
    updateStaff: builder.mutation({
      query: (payload) => ({
        url: `/staffs/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'Staffs',
        { type: 'staffs', id },
      ],
    }),

    // 👉 Delete Staff
    deleteStaff: builder.mutation({
      query: (id) => {
        return {
          url: `/staffs/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Staffs'],
    }),
  }),
});

export const {
  useGetAllStaffsQuery,
  useGetStaffDetailsQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = staffsService;
