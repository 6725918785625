import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const rewardsService = createApi({
  reducerPath: 'rewards',
  baseQuery: baseQuery,
  tagTypes: ['Rewards'],
  endpoints: (builder) => ({
    // 👉 Get All Products
    getRewards: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/rewards',
          params: {
            page: current,
            per_page: pageSize,
            'filter[query]': q,
            ...arg,
          },
        };
      },
      providesTags: ['Rewards'],
    }),

    // 👉 Delete Reward
    deleteReward: builder.mutation({
      query: (id) => {
        return {
          url: `/rewards/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Rewards'],
    }),
  }),
});

export const {
  useGetRewardsQuery,
  useDeleteRewardMutation
} = rewardsService;
