import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const orderService = createApi({
  reducerPath: 'orderService',
  baseQuery: baseQuery,
  tagTypes: ['Orders'],
  endpoints: (builder) => ({
    // 👉 Get All Orders
    getOrders: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: `/orders`,
          params: {
            page: current,
            per_page: pageSize,
            ...arg,
            'filter[query]': q,
          },
        };
      },
      providesTags: ['Orders'],
    }),

    // 👉 Get Order
    getOrder: builder.query({
      query: (id) => {
        return {
          url: `/orders/${id}`,
        };
      },
      providesTags: (result, error, { id }) => [{ type: 'Order', id }],
    }),

    // 👉 Get Order Deliveries
    getOrderDeliveries: builder.query({
      query: (arg) => {
        const { current, pageSize } = arg;

        return {
          url: `/orders/${arg.id}/deliveries`,
          params: { page: current, per_page: pageSize, ...arg },
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'OrderDeliveries', id: arg.id },
      ],
    }),

    // 👉 Get Order Details
    getOrderDetails: builder.query({
      query: (arg) => {
        return {
          url: `/orders/${arg}`,
        };
      },
      providesTags: (result, error, arg) => [{ type: 'Order', id: arg }],
    }),

    // 👉 Create Order
    createOrder: builder.mutation({
      query: (data) => ({
        url: 'orders',
        method: 'POST',
        body: data,
      }),

      // invalidatesTags: (result, error, { id }) => [{ type: 'Order', id: id }],
    }),

    // 👉 Update Order Delivery Data
    updateOrderDeliveryData: builder.mutation({
      query: (payload) => {
        return {
          url: `/orders/${payload.id}/stats`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Order', id }],
    }),

    // 👉 Reschedule Order Delivery Data
    rescheduleOrder: builder.mutation({
      query: (payload) => {
        return {
          url: `/orders/${payload.id}/reschedule`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Order', id },
        { type: 'OrderDeliveries', id },
      ],
    }),

    // 👉 Changes Order Address
    changeOrderAddress: builder.mutation({
      query: (payload) => {
        return {
          url: `/orders/${payload.id}/change-address?_method=PATCH`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Order', id },
        { type: 'SubscriptionDeliveries', id },
        { type: 'Subscription', id },
      ],
    }),

    // 👉 Cancel Order Details
    cancelOrder: builder.mutation({
      query: (id) => {
        return {
          url: `/orders/${id}/cancel`,
          method: 'GET',
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Order', id }],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderDetailsQuery,
  useCreateOrderMutation,
  useGetOrderQuery,
  useGetOrderDeliveriesQuery,
  useCancelOrderMutation,
  useUpdateOrderDeliveryDataMutation,
  useRescheduleOrderMutation,
  useChangeOrderAddressMutation,
} = orderService;
