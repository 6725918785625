import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const subscriptionService = createApi({
  reducerPath: 'subscriptionService',
  baseQuery: baseQuery,
  tagTypes: ['Subscriptions'],
  endpoints: (builder) => ({
    // 👉 Get All Subscriptions
    getSubscriptions: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/subscriptions',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Subscriptions'],
    }),

    // 👉 Get Subscription Details
    getSubscription: builder.query({
      query: (id) => {
        return {
          url: `/subscriptions/${id}`,
        };
      },

      providesTags: (result, error, arg) => [{ type: 'Subscription', id: arg }],
    }),

    // 👉 Get Subscription Details
    cancelSubscription: builder.mutation({
      query: (id) => {
        return {
          url: `/subscriptions/${id}/cancel`,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Subscription', id },
        { type: 'SubscriptionTimeLine', id: id },
        { type: 'SubscriptionDeliveries', id },
      ],
    }),

    // 👉 Pause Subscription Details
    pauseSubscription: builder.mutation({
      query: (id) => {
        return {
          url: `/subscriptions/${id}/pause`,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Subscription', id },
        { type: 'SubscriptionTimeLine', id: id },
        { type: 'SubscriptionDeliveries', id: id },
      ],
    }),

    // 👉 Resume Subscription Details
    resumeSubscription: builder.mutation({
      query: (payload) => {
        return {
          url: `/subscriptions/${payload.id}/resume`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Subscription', id },
        { type: 'SubscriptionTimeLine', id: id },
        { type: 'SubscriptionDeliveries', id: id },
      ],
    }),

    // 👉 Create Subscription Deliveries
    createSubscriptionDeliveries: builder.mutation({
      query: (payload) => {
        return {
          url: `/subscriptions/${payload.id}/deliveries/create`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Subscription', id },
        { type: 'SubscriptionTimeLine', id: id },
        { type: 'SubscriptionDeliveries', id: id },
      ],
    }),

    // 👉 Update Subscription Delivery Data
    updateSubscriptionDeliveryData: builder.mutation({
      query: (payload) => {
        return {
          url: `/subscriptions/${payload.id}/stats`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Subscription', id },
        { type: 'SubscriptionTimeLine', id: id },
        { type: 'SubscriptionDeliveries', id: id },
      ],
    }),

    // 👉 Get Subscription Timeline
    getSubscriptionTimeline: builder.query({
      query: (id) => {
        return {
          url: `/subscriptions/${id}/logs`,
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'SubscriptionTimeLine', id: arg },
      ],
    }),

    // 👉 Get Subscription Deliveries
    getSubscriptionDeliveries: builder.query({
      query: (arg) => {
        const { current, pageSize } = arg;

        return {
          url: `/subscriptions/${arg.id}/deliveries`,
          params: { page: current, per_page: pageSize, ...arg },
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'SubscriptionDeliveries', id: arg.id },
      ],
    }),

    // 👉 Change Delivery Status
    changeOrderStatus: builder.mutation({
      query: (payload) => ({
        url: `/subscriptions/${payload.get('id')}/change-status`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Deliveries'],
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionQuery,
  useGetSubscriptionTimelineQuery,
  useGetSubscriptionDeliveriesQuery,
  useCancelSubscriptionMutation,
  usePauseSubscriptionMutation,
  useResumeSubscriptionMutation,
  useCreateSubscriptionDeliveriesMutation,
  useUpdateSubscriptionDeliveryDataMutation,
  useChangeOrderStatusMutation
} = subscriptionService;
