import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import customer from './slices/customerSlice';

import {
  carrierService,
  customerService,
  messageService,
  orderService,
  productsService,
  productSearchService,
  subscriptionService,
  sliderService,
  popupService,
  deliveryService,
  complainService,
  categoriesService,
  bottleService,
  couponsService,
  staffsService,
  walletsService,
  rewardsService,
  dropPointService
} from 'services';
import { customRouteSlice } from './slices/customRouteSlice';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    customRoute: customRouteSlice.reducer,
    customer,
    [customerService.reducerPath]: customerService.reducer,
    [subscriptionService.reducerPath]: subscriptionService.reducer,
    [carrierService.reducerPath]: carrierService.reducer,
    [messageService.reducerPath]: messageService.reducer,
    [productsService.reducerPath]: productsService.reducer,
    [productSearchService.reducerPath]: productSearchService.reducer,
    [orderService.reducerPath]: orderService.reducer,
    [sliderService.reducerPath]: sliderService.reducer,
    [popupService.reducerPath]: popupService.reducer,
    [deliveryService.reducerPath]: deliveryService.reducer,
    [complainService.reducerPath]: complainService.reducer,
    [categoriesService.reducerPath]: categoriesService.reducer,
    [bottleService.reducerPath]: bottleService.reducer,
    [couponsService.reducerPath]: couponsService.reducer,
    [staffsService.reducerPath]: staffsService.reducer,
    [walletsService.reducerPath]: walletsService.reducer,
    [rewardsService.reducerPath]: rewardsService.reducer,
    [dropPointService.reducerPath]: dropPointService.reducer,
    ...asyncReducers,
  });

  return combinedReducer(state, action);
};

export default rootReducer;
