import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const sliderService = createApi({
  reducerPath: 'sliderService',
  baseQuery: baseQuery,
  tagTypes: ['SquareSlider'],
  endpoints: (builder) => ({
    getSquareSlider: builder.query({
      query: () => {
        return {
          url: `/sliders/square`,
        };
      },
      providesTags: ['SquareSlider'],
    }),

    // 👉 Get All Products
    getSliderBanners: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/sliders/banners',
          params: {
            ...arg,
            page: current,
            per_page: pageSize,
            'filter[query]': q,
          },
        };
      },
      providesTags: ['SliderBanners'],
    }),

    getSliderBannerDetails: builder.query({
      query: (id) => {
        return {
          url: `/sliders/banners/${id}`,
        };
      },
      providesTags: (result, error, arg) => [{ type: 'Banner', id: arg }],
    }),

    updateSliderBanner: builder.mutation({
      query: (payload) => ({
        url: `/sliders/banners/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'SliderBanners',
        { type: 'Banner', id },
      ],
    }),

    createSliderBanner: builder.mutation({
      query: (payload) => ({
        url: `/sliders/banners`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SliderBanners'],
    }),

    getRectangularSlider: builder.query({
      query: () => {
        return {
          url: `/sliders/rect`,
        };
      },
      providesTags: ['RectangularSlider'],
    }),

    getNewArrivalSlider: builder.query({
      query: () => {
        return {
          url: `/sliders/new-arrival`,
        };
      },
      providesTags: ['NewArrivalSlider'],
    }),

    addSlider: builder.mutation({
      query: (payload) => ({
        url: `/sliders`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SquareSlider', 'RectangularSlider'],
    }),

    addNewArrivalSlider: builder.mutation({
      query: (payload) => ({
        url: `/sliders/new-arrival`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['NewArrivalSlider'],
    }),

    updateNewArrivalSlider: builder.mutation({
      query: (payload) => ({
        url: `/sliders/new-arrival/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['NewArrivalSlider'],
    }),

    updateSlider: builder.mutation({
      query: (payload) => ({
        url: `/sliders/${payload.get('id')}/update?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['RectangularSlider', 'SquareSlider'],
    }),

    deleteSlider: builder.mutation({
      query: (id) => ({
        url: `/sliders/${id}/delete`,
        method: 'GET',
      }),
      invalidatesTags: [
        'SquareSlider',
        'RectangularSlider',
        'NewArrivalSlider',
      ],
    }),
  }),
});

export const {
  useGetSquareSliderQuery,
  useGetSliderBannersQuery,
  useGetSliderBannerDetailsQuery,
  useGetRectangularSliderQuery,
  useGetNewArrivalSliderQuery,
  useAddSliderMutation,
  useDeleteSliderMutation,
  useUpdateSliderBannerMutation,
  useCreateSliderBannerMutation,
  useAddNewArrivalSliderMutation,
  useUpdateNewArrivalSliderMutation,
  useUpdateSliderMutation,
} = sliderService;
