import {
  DIR_LTR,
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
} from 'constants/ThemeConstant';

export const ENV = 'production'; // local, staging, production

export const APP_NAME = 'Sundarini Console';

export const API_BASE_URL = {
  local: 'http://127.0.0.1:8000/api/console/v1',
  staging: 'https://sundarini-api.webstep.in/api/console/v1',
  production: 'https://sundarini-api.webstep.in/api/console/v1',
}[ENV];

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';

// export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/customer/default`;
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/customers`;
export const UNAUTHENTICATED_ENTRY = '/login';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
  blankLayout: false,
};
