import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const dropPointService = createApi({
  reducerPath: 'dropPoint',
  baseQuery: baseQuery,
  tagTypes: ['DropPoint'],
  endpoints: (builder) => ({
    // 👉 Get All Drop Points
    getAllDropPoints: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/drop-points',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['DropPoint'],
    }),

    // 👉 Create Drop Point
    createDropPoint: builder.mutation({
      query: (payload) => {
        return {
          url: `/drop-points`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['DropPoint'],
    }),

    // 👉 Update Drop Point Details
    updateDropPoint: builder.mutation({
      query: (payload) => ({
        url: `/drop-points/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'DropPoint',
        { type: 'dropPoint', id },
      ],
    }),

    // 👉 Delete Drop Point
    deleteDropPoint: builder.mutation({
      query: (id) => {
        return {
          url: `/drop-points/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['DropPoint'],
    }),
  }),
});

export const {
  useGetAllDropPointsQuery,
  useCreateDropPointMutation,
  useUpdateDropPointMutation,
  useDeleteDropPointMutation,
} = dropPointService;
