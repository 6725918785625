import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    tabName: ''
};

export const customRouteSlice = createSlice({
    name: 'tabname',
    initialState: initialState,

    reducers: {
        saveTabName: (state, action) => {
            console.log("State before update:", action.payload);
            state.tabName = action.payload;
        },
        deleteTabName: (state) => {
            console.log("State before deletion:", state);
            state.tabName = '';
            console.log("State after deletion:", state);
        }
    },
});

export const { saveTabName, deleteTabName } = customRouteSlice.actions;
