import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [401];

const axiosIns = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

// 👉 Config
const TOKEN_PAYLOAD_KEY = 'AUTHORIZATION';

// 👉 API Request interceptor
axiosIns.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN) || null;

    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    notification.error({ message: 'Error' });

    return Promise.reject(error);
  },
);

// 👉 API Response interceptor
axiosIns.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let notificationParam = {
      message: '',
    };

    // Remove token and redirect
    if (unauthorizedCode.includes(error.response.status)) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(AUTH_TOKEN);

      store.dispatch(signOutSuccess());
    }

    if (error.response.status === 400) {
      notificationParam.message = 'Something Went Wrong!';
      notificationParam.description = error.response.data.message;
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  },
);

export default axiosIns;
