import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const bottleService = createApi({
  reducerPath: 'bottleService',
  baseQuery: baseQuery,
  tagTypes: ['Bottles'],
  endpoints: (builder) => ({
    // 👉 Get Bottle
    getBottles: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/bottles',
          params: {
            ...arg,
            page: current,
            per_page: pageSize,
            'filter[query]': q,
          },
        };
      },
      providesTags: [{ type: 'Bottles', id: 'LIST' }],
    }),

    // 👉 Update Bottle Status
    updateBottleStatus: builder.mutation({
      query: (arg) => {
        const id = arg.get('id');

        return {
          url: `/bottles/${id}?_method=PATCH`,
          method: 'POST',
          body: arg,
        };
      },
      invalidatesTags: [{ type: 'Bottles', id: 'LIST' }],
    }),

    // 👉 Update Bottle Status
    updateMultipleBottleStatus: builder.mutation({
      query: (payload) => {
        return {
          url: `/bottles/statuses`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Bottles', id: 'LIST' },
        { type: 'CustomerBottles', id: arg.customer_id },
      ],
    }),

    // 👉 Send SMS To The Selected Bottles
    sendMultipleBottleSMS: builder.mutation({
      query: (payload) => {
        return {
          url: `/bottles/sms`,
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetBottlesQuery,
  useUpdateBottleStatusMutation,
  useUpdateMultipleBottleStatusMutation,
  useSendMultipleBottleSMSMutation,
} = bottleService;
