import {createSlice} from '@reduxjs/toolkit';
import userData from 'assets/data/user-list.data.json';

export const initialState = {
    users: userData,
    userProfileVisible: false,
    selectedUser: null,
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState: initialState,

    reducers: {
        deleteCustomer: (state, action) => {
            const users = state.users.filter((el) => el.id !== action.payload);

            return {...state, users};
        },

        showCustomer: (state, action) => {
            return {
                ...state,
                userProfileVisible: true,
                selectedUser: action.payload,
            };
        },

        closeCustomer: (state) => {
            return {
                ...state,
                userProfileVisible: false,
                selectedUser: null,
            };
        },
    },
});

export const {deleteCustomer, showCustomer, closeCustomer} = customerSlice.actions;

export default customerSlice.reducer;
