import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const deliveryService = createApi({
  reducerPath: 'deliveryService',
  baseQuery: baseQuery,
  tagTypes: ['Deliveries'],
  endpoints: (builder) => ({
    // 👉 Get All Deliveries
    getDeliveries: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: `/deliveries`,
          params: {
            page: current,
            per_page: pageSize,
            ...arg,
            'filter[query]': q,
          },
        };
      },
      providesTags: ['Deliveries'],
    }),

    // 👉 Get All Addresses For A Delivery
    getDeliveryAddresses: builder.query({
      query: (arg) => {
        return {
          url: `/deliveries/${arg}/address`,
        };
      },
    }),

    // 👉 Change Delivery Addresses For A Delivery
    changeDeliveryAddress: builder.mutation({
      query: (payload) => {
        return {
          url: `/deliveries/${payload.id}/address`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Deliveries'],
    }),

    // 👉 Change Delivery Status
    changeDeliveryStatus: builder.mutation({
      query: (payload) => ({
        url: `/deliveries/${payload.get('id')}/change-status`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Deliveries'],
    }),

    // 👉 Change Delivery Carrier
    changeDeliveryCarrier: builder.mutation({
      query: (payload) => ({
        url: `/deliveries/${payload.get('id')}/assign`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Deliveries'],
    }),
  }),
});

export const {
  useGetDeliveriesQuery,
  useGetDeliveryAddressesQuery,
  useChangeDeliveryAddressMutation,
  useChangeDeliveryStatusMutation,
  useChangeDeliveryCarrierMutation,
} = deliveryService;
