import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const categoriesService = createApi({
  reducerPath: 'categories',
  baseQuery: baseQuery,
  tagTypes: ['Categories','CategorySlider'],
  endpoints: (builder) => ({
    // 👉 Get All Categories
    getAllCategories: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/categories',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Categories'],
    }),

    // 👉 Get Category Details
    getCategoryDetails: builder.query({
      query: (id) => ({
        url: `/categories/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Categories', id: arg }],
    }),

    // 👉 Create Category
    createCategory: builder.mutation({
      query: (payload) => {
        return {
          url: `/categories`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Categories'],
    }),

    // 👉 Update Category Details
    updateCategory: builder.mutation({
      query: (payload) => ({
        url: `/categories/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'Categories',
        { type: 'categories', id },
      ],
    }),

    // 👉 Delete Category
    deleteCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/categories/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Categories'],
    }),

    // 👉 Get Category Sliders
    getSliderBanners: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/sliders/banners',
          params: {
            ...arg,
            page: current,
            per_page: pageSize,
            'filter[query]': q,
          },
        };
      },
      providesTags: ['SliderBanners'],
    }),
    getCategorySliders: builder.query({
      query: (id) => {

        return {
          url: `/categories/${id}/sliders`,
        };
      },
      providesTags: ['CategorySlider'],
    }),

    // 👉 Add Category Sliders
    addCategorySlider: builder.mutation({
      query: (payload) => ({
        url: `/categories/${payload.get('category_id')}/sliders`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CategorySlider'],
    }),

    // 👉 Update Category Sliders
    updateCategorySlider: builder.mutation({
      query: (payload) => ({
        url: `/categories/${payload.get('category_id')}/sliders/${payload.get('slider_id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CategorySlider'],
    }),

    // 👉 Delete Category Sliders
    deleteCategorySlider: builder.mutation({
      query: (payload) => ({
        url: `/categories/${payload.category_id}/sliders/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CategorySlider'],
    }),

  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetCategorySlidersQuery,
  useAddCategorySliderMutation,
  useUpdateCategorySliderMutation,
  useDeleteCategorySliderMutation,
  useGetCategoryDetailsQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesService;
