import fetch from 'auth/FetchInterceptor';

const AuthService = {};

AuthService.login = function (data) {
  return fetch.post('/auth/login', data);
};

AuthService.logout = function () {
  return fetch({
    url: '/auth/logout',
    method: 'post',
  });
};

export default AuthService;
