import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const popupService = createApi({
  reducerPath: 'popupService',
  baseQuery: baseQuery,
  tagTypes: ['popup'],
  endpoints: (builder) => ({

    getPopup: builder.query({
      query: () => {
        return {
          url: `/app-pop-up`,
          method: 'GET',
        };
      },
      providesTags: ['popup'],
    }),

    updatePopup: builder.mutation({
      query: (payload) => ({
        url: `/app-pop-up`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['popup'],
    }),

  }),
});

export const {
  useGetPopupQuery,
  useUpdatePopupMutation,
} = popupService;
