import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const walletsService = createApi({
  reducerPath: 'walletsService',
  baseQuery: baseQuery,
  tagTypes: ['Wallets'],
  endpoints: (builder) => ({
    // 👉 Get All Subscriptions
    getWallets: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/wallets',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Wallets'],
    }),

    // 👉 Get Subscription Details
    getWalletTransactions: builder.query({
      query: (arg) => {
        const { id, current, pageSize, q } = arg;

        return {
          url: `/wallets/${id}/transactions`,
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },

    //   providesTags: ['Wallets'],
    }),

    // 👉 Get All Transactions
    getAllTransactions: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/transactions',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },

    //   providesTags: ['Wallets'],
    }),

  }),
});

export const {
  useGetWalletsQuery,
  useGetWalletTransactionsQuery,
  useGetAllTransactionsQuery
} = walletsService;
