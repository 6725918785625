import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const productSearchService = createApi({
    reducerPath: 'productSearchService',
    baseQuery: baseQuery,
    tagTypes: ['ProductSearch'],
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => {
                return {
                    url: '/products/categories',
                };
            },
            providesTags: ['ProductCategory'],
        }),

        getProductsByCategory: builder.query({
            query: (args) => {
                return {
                    url: `/products/${args.id}/${args.frequency}/list`,
                };
            },
            providesTags: ['ProductByCategory'],
        }),
    }),
});

export const {useGetCategoriesQuery, useGetProductsByCategoryQuery} = productSearchService;
