import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const customerService = createApi({
  reducerPath: 'customerService',
  baseQuery: baseQuery,
  tagTypes: ['Customers', 'CustomersBottles'],
  endpoints: (builder) => ({
    // 👉 Get all customers
    getCustomers: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/customers',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Customers'],
    }),

    // 👉 Get Customer By ID
    getCustomer: builder.query({
      query: (id) => {
        return {
          url: `/customers/${id}`,
        };
      },
      providesTags: ['Customer'],
    }),

    // 👉 Get Customer Transaction
    getCustomerTransactions: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/transactions`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['Transactions'],
    }),

    // 👉 Get Customer Cart
    getCustomerCarts: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/carts`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['Carts'],
    }),

    // 👉 Delete Customer All Carts
    deleteCustomerAllCarts: builder.mutation({
      query: (id) => {
        return {
          url: `/customers/${id}/carts`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Carts'],
    }),

    // 👉 Delete Customer Cart
    deleteCustomerCart: builder.mutation({
      query: (id) => {
        return {
          url: `/carts/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Carts'],
    }),

    // 👉 Get Customer Bottles
    getCustomerBottles: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/bottles`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'CustomersBottles', id: arg.id },
      ],
    }),

    // 👉 Get Customer Complain
    getCustomerComplains: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/complains`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['CustomerComplain'],
    }),

    // 👉 Get Customer Reviews
    getCustomerReviews: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/reviews`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: (result, error, arg) => {
        return [{ type: 'CustomerReviews', id: arg.id }];
      },
    }),

    // 👉 Get Reward
    getCustomerRewards: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/rewards`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['Rewards'],
    }),

    // 👉 Delete Customer Reward
    deleteCustomerReward: builder.mutation({
      query: (id) => {
        return {
          url: `/customers/${id}/rewards`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Carts'],
    }),


    // 👉 Get Customer Wallet
    getWalletData: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `customers/${id}/wallet`,
          params: { page: current, per_page: pageSize },
        };
      },
    }),

    // 👉 Adjust Customer Wallet
    adjustWallet: builder.mutation({
      query: (payload) => ({
        url: `/customers/${payload.id}/wallet/adjust`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Transactions'],
    }),

    // 👉 Adjust Customer Bottle Charge
    adjustBottleCharge: builder.mutation({
      query: (payload) => ({
        url: `/customers/${payload.id}/bottle-charge`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Customer'],
    }),

    // 👉 Create Customer Address
    createCustomerAddress: builder.mutation({
      query: (payload) => ({
        url: `/customers/${payload.user_id}/addresses`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CustomerAddresses', 'CustomerAddressesAll'],
    }),

    // 👉 Update Customer Address
    updateCustomerAddress: builder.mutation({
      query: (payload) => ({
        url: `/addresses/${payload.id}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CustomerAddresses', 'CustomerAddressesAll'],
    }),

    // 👉 Get Customer Addresses Without pagination (For Dropdown)
    getCustomerAddresses: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/addresses`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['CustomerAddresses'],
    }),

    // 👉 Get Customer Addresses
    getCustomerAddressesAll: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/addresses-all`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['CustomerAddressesAll'],
    }),

    // 👉 Get Customer Deliveries
    getCustomerDeliveries: builder.query({
      query: (arg) => {
        const { current, pageSize, id } = arg;

        return {
          url: `/customers/${id}/deliveries`,
          params: { page: current, per_page: pageSize },
        };
      },
      providesTags: ['CustomerDeliveries'],
    }),

    // 👉 Get Customer Subscriptions
    getCustomerSubscription: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/subscriptions`,
          params: { page: current, per_page: pageSize },
        };
      },
    }),

    // 👉 Get Customer Orders
    getCustomerOrders: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/customers/${id}/orders`,
          params: { page: current, per_page: pageSize },
        };
      },
    }),

    // 👉 Get Address Apartments
    getApartments: builder.query({
      query: () => {
        return {
          url: `/addresses/apartments`,
        };
      },
    }),

    // 👉 Send Push To Customer
    sendCustomerPush: builder.mutation({
      query: (payload) => ({
        url: `/push/${payload.get('id')}`,
        method: 'POST',
        body: payload,
      }),
    }),

    // 👉 Delete Address
    deleteAddress: builder.mutation({
      query: (id) => {
        return {
          url: `addresses/${id}/delete`,
          method: 'GET',
        };
      },
      invalidatesTags: ['CustomerAddresses', 'CustomerAddressesAll'],
    }),

    // 👉 Mark Address As Default
    markAddressAsDefault: builder.mutation({
      query: (id) => {
        return {
          url: `addresses/${id}/default`,
          method: 'GET',
        };
      },
      invalidatesTags: ['CustomerAddresses', 'CustomerAddressesAll'],
    }),

    // 👉 Update Customer
    updateCustomer: builder.mutation({
      query: (payload) => {
        return {
          url: `customers/${payload.get('id')}?_method=PATCH`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Customers', 'Customer'],
    }),

    // 👉 Create Customer
    createCustomer: builder.mutation({
      query: (payload) => {
        return {
          url: `customers`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Customers', 'Customer'],
    }),

    // 👉 Get Dashboard
    getDashboard: builder.query({
      query: () => {
        return {
          url: '/dashboard',
        };
      },
      providesTags: ['Dashboard'],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useGetCustomerTransactionsQuery,
  useGetCustomerCartsQuery,
  useDeleteCustomerAllCartsMutation,
  useDeleteCustomerCartMutation,
  useGetCustomerBottlesQuery,
  useGetCustomerReviewsQuery,
  useGetCustomerComplainsQuery,
  useGetCustomerAddressesQuery,
  useGetCustomerAddressesAllQuery,
  useGetWalletDataQuery,
  useAdjustWalletMutation,
  useGetCustomerDeliveriesQuery,
  useGetCustomerSubscriptionQuery,
  useGetCustomerOrdersQuery,
  useGetApartmentsQuery,
  useGetCustomerRewardsQuery,
  useDeleteCustomerRewardMutation,
  useCreateCustomerAddressMutation,
  useSendCustomerPushMutation,
  useDeleteAddressMutation,
  useMarkAddressAsDefaultMutation,
  useUpdateCustomerMutation,
  useUpdateCustomerAddressMutation,
  useCreateCustomerMutation,
  useGetDashboardQuery,
  useAdjustBottleChargeMutation,
} = customerService;
