import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const carrierService = createApi({
  reducerPath: 'carrierService',
  baseQuery: baseQuery,
  tagTypes: ['Carriers', 'AllCarriers'],
  endpoints: (builder) => ({
    getCarriers: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/carriers',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Carriers'],
    }),

    getCarrier: builder.query({
      query: (id) => {
        return {
          url: `/carriers/${id}`,
        };
      },
      providesTags: ['Carrier'],
    }),

    getCarrierDeliveries: builder.query({
      query: (arg) => {
        const { id, current, pageSize, q, date } = arg;

        return {
          url: `/carriers/${id}/deliveries`,
          params: {
            page: current,
            per_page: pageSize,
            'filter[query]': q,
            'filter[delivery_date]': date,
          },
        };
      },
      providesTags: ['Carriers'],
    }),

    getAllCarriers: builder.query({
      query: () => {
        return {
          url: '/all-carriers',
        };
      },
      providesTags: ['AllCarriers'],
    }),

    getCarrierDropPoints: builder.query({
      query: () => {
        return {
          url: '/carriers/drop-points',
        };
      },
      providesTags: ['CarrierDropPoints'],
    }),

    createCarrier: builder.mutation({
      query: (payload) => ({
        url: `/carriers`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Carriers'],
    }),

    // 👉 Update Carrier
    updateCarrier: builder.mutation({
      query: (payload) => {
        return {
          url: `carriers/${payload.get('id')}?_method=PATCH`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Carriers', 'Carrier'],
    }),

    // 👉 Create Guest Delivery
    createGuestDelivery: builder.mutation({
      query: (payload) => ({
        url: '/guest-deliveries',
        method: 'POST',
        body: payload,
      }),
    }),

    // 👉 Replace Carrier
    replaceCarrier: builder.mutation({
      query: (payload) => ({
        url: `/carriers/replace`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Carriers'],
    }),

    terminateCarrier: builder.mutation({
      query: (payload) => ({
        url: `/carriers/terminate`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Carriers'],
    }),

    // 👉 Replace Carrier
    transferCarrierDelivery: builder.mutation({
      query: (payload) => ({
        url: `/carriers/transfer`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Carriers'],
    }),

    // 👉 Get Carrier Address
    getCarrierAddress: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/carriers/${id}/addresses`,
          params: { page: current, per_page: pageSize, ...arg },
        };
      },

      invalidatesTags: ['Carriers'],
    }),

    // 👉 Get Carrier Bottles
    getCarrierBottles: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/carriers/${id}/bottles`,
          params: { page: current, per_page: pageSize, ...arg },
        };
      },

      invalidatesTags: ['Carriers'],
    }),

    // 👉 Carrier Cancel Request
    cancelCarrierRequest: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/carriers/${id}/cancel-requests`,
          params: { page: current, per_page: pageSize, ...arg },
        };
      },

      invalidatesTags: ['Carriers'],
    }),

    // 👉 Carrier Cancel Request
    deliveryTransfers: builder.query({
      query: (arg) => {
        const { id, current, pageSize } = arg;

        return {
          url: `/carriers/${id}/delivery-transfers`,
          params: { page: current, per_page: pageSize, ...arg },
        };
      },

      invalidatesTags: ['Carriers'],
    }),

    // 👉 Send message to multiple carrier
    sendSMSToCarriers: builder.mutation({
      query: (payload) => ({
        url: `/carriers/sms`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCarriersQuery,
  useGetCarrierQuery,
  useGetCarrierDeliveriesQuery,
  useGetAllCarriersQuery,
  useGetCarrierDropPointsQuery,
  useCreateGuestDeliveryMutation,
  useCreateCarrierMutation,
  useReplaceCarrierMutation,
  useTerminateCarrierMutation,
  useUpdateCarrierMutation,
  useTransferCarrierDeliveryMutation,
  useGetCarrierAddressQuery,
  useGetCarrierBottlesQuery,
  useCancelCarrierRequestQuery,
  useDeliveryTransfersQuery,
  useSendSMSToCarriersMutation,
} = carrierService;
