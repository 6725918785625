import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const productsService = createApi({
  reducerPath: 'products',
  baseQuery: baseQuery,
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    // 👉 Get All Products
    getProducts: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/products',
          params: {
            page: current,
            per_page: pageSize,
            'filter[query]': q,
            ...arg,
          },
        };
      },
      providesTags: ['Products'],
    }),

    // 👉 Get Product Details
    getProductDetails: builder.query({
      query: (id) => ({
        url: `/products/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Product', id: arg }],
    }),

    // 👉 Get Product Details
    updateProduct: builder.mutation({
      query: (payload) => ({
        url: `/products/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'Products',
        { type: 'Product', id },
      ],
    }),

    // 👉 Get Product Details
    createProduct: builder.mutation({
      query: (payload) => ({
        url: `/products`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'Products',
        { type: 'Product', id },
      ],
    }),

    // 👉 Update Product Status
    updateProductStatus: builder.mutation({
      query: (payload) => {
        return {
          url: `/products/${payload.id}/status`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Products'],
    }),

    // 👉 Delete Product
    deleteProduct: builder.mutation({
      query: (id) => {
        return {
          url: `/products/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductDetailsQuery,
  useUpdateProductStatusMutation,
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
} = productsService;
