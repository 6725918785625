import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const couponsService = createApi({
  reducerPath: 'coupons',
  baseQuery: baseQuery,
  tagTypes: ['Coupons'],
  endpoints: (builder) => ({
    // 👉 Get All Coupons
    getAllCoupons: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/coupons',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['Coupons'],
    }),

    // 👉 Get Coupon Details
    getCouponDetails: builder.query({
      query: (id) => ({
        url: `/coupons/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Coupons', id: arg }],
    }),

    // 👉 Create Coupon
    createCoupon: builder.mutation({
      query: (payload) => {
        return {
          url: `/coupons`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Coupons'],
    }),

    // 👉 Update Coupon Details
    updateCoupon: builder.mutation({
      query: (payload) => ({
        url: `/coupons/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        'Coupons',
        { type: 'coupons', id },
      ],
    }),

    // 👉 Delete Coupon
    deleteCoupon: builder.mutation({
      query: (id) => {
        return {
          url: `/coupons/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Coupons'],
    }),
  }),
});

export const {
  useGetAllCouponsQuery,
  useGetCouponDetailsQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} = couponsService;
