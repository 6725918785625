import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const complainService = createApi({
  reducerPath: 'complains',
  baseQuery: baseQuery,
  tagTypes: ['Complains'],
  endpoints: (builder) => ({
    // 👉 Get All Products
    getComplains: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/complains',
          params: {
            ...arg,
            page: current,
            per_page: pageSize,
            'filter[query]': q,
          },
        };
      },
      providesTags: ['Complains'],
    }),

    // 👉 Change Delivery Status
    changeComplainStatus: builder.mutation({
      query: (payload) => ({
        url: `/complains/${payload.get('id')}?_method=PATCH`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Complains'],
    }),
  }),
});

export const { useGetComplainsQuery, useChangeComplainStatusMutation } =
  complainService;
