import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const messageService = createApi({
  reducerPath: 'messageService',
  baseQuery: baseQuery,
  tagTypes: ['Messages'],
  endpoints: (builder) => ({
    // 👉 Get All Messages
    getMessages: builder.query({
      providesTags: ['Messages'],
      query: () => {
        return {
          url: '/deliveries/messages',
        };
      },
    }),

    // 👉 Get A Single Message
    getMessage: builder.query({
      providesTags: ['Message'],
      query: (type) => {
        return {
          url: '/deliveries/messages',
          method: 'POST',
          body: { type },
        };
      },
    }),

    // 👉 Send Message
    sendMessage: builder.mutation({
      query: (payload) => ({
        url: '/deliveries/message-send',
        method: 'POST',
        body: payload,
      }),
    }),

    // 👉 Send Push Notification To All Users
    sendPushNotificationToAll: builder.mutation({
      query: (payload) => ({
        url: '/push-to-all',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useGetMessageQuery,
  useSendMessageMutation,
  useSendPushNotificationToAllMutation,
} = messageService;
