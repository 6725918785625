import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {AUTH_TOKEN, USER_KEY} from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  user: JSON.parse(localStorage.getItem(USER_KEY)) || null,
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.login({ email, password });

      localStorage.setItem(AUTH_TOKEN, response.token);
      localStorage.setItem(USER_KEY, JSON.stringify(response.user));

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signOut = createAsyncThunk('auth/logout', async () => {
  const response = await FirebaseService.signOutRequest();

  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER_KEY);

  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = '/';
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = '';
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = '/';
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = '/';
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.user = null;
        state.redirect = '/';
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
